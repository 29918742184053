<!-- 大屏展示 -->
<template>
    <div :class="['content', theme == 1||theme ==3 ? 'select_menu_context_dark' : 'select_menu_context_light']">
        <div class="screenChooseBox" v-for="(item, index) in screenList" :key="index" @click.stop.prevent="handSelect(item.menu_url)">
            <div class="screenChooseItem">
                <template v-if="item.screen_pic">
                    <el-image :src="item.screen_pic" fit="fill"></el-image>
                </template>
                <template v-else>
                    <div class="noScreenPic"></div>
                </template>
            </div>
            <div class="screenChooseName">
                {{ item.show_name }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: 'screenSelection',
  components: {},
  props: {},
  data () {
    return {
      screenList: []
    }
  },
  watch: {},
  computed: {
    ...mapGetters('menuList', ['theme', 'shortcutList', 'groupInfo']),
    ...mapState('menuList', [
      'activeIndex',
      'groupInfo',
      'hearderMenuList',
      'activeMenu',
      'activeHearderMenu',
      'allMenuList',
      'isShowMsg',
      'isBigScreen',
      'isBigScreenShowModule',
      'leftMenuList',
      'isMenuRoute',
      'isWorkSpace',
      'activeTabList'
    ])
  },
  methods: {
    ...mapMutations('menuList', [
      'setLeftMenuList',
      'setActiveHearderMenu',
      'setActiveMenu',
      'setActiveIndex',
      'setMenuList',
      'addTab',
      'setDevAndHelpList',
      'setThree3DUrl',
      'setIsBigScreen'
    ]),
    handSelect (path) {
      if (!path) return
      this.setIsBigScreen(path)
      this.setLeftMenuList([])
      this.setActiveMenu(path)
    }
  },
  created () { },
  mounted () {
    // console.log('hearderMenuList', this.hearderMenuList)
    if (this.hearderMenuList.length > 0) {
      const filterScreen = this.hearderMenuList.filter(item => {
        return item.menu_name === '大屏'
      })
      console.log('filterScreen', filterScreen)
      if (filterScreen) {
        this.screenList = filterScreen[0].children
      } else {
        this.screenList = []
      }
    }
  }
}
</script>

<style scoped lang="less">
.select_menu_context_dark {
    background-image: url('~@/assets/images/screenBackDark.png');
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.select_menu_context_light {
    background-image: url('~@/assets/images/screenBackLight.png');
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.content {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    width: 100%;
    .screenChooseBox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 1%;
        width: 31%;
        height: 45%;
        cursor: pointer;
        .screenChooseItem {
            width: 100%;
            height: 85%;
            border: 1px solid#01A6FB;
            box-sizing: border-box;

            .el-image {
                width: 100%;
                height: 100%;

                .el-image__error {
                    background-image: url('~@/assets/img/noMessage/failloadpic.png');
                    background-size: 30% 60%;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-color: transparent;
                    font-size: 0px;
                }
            }

            .noScreenPic {
                width: 100%;
                height: 100%;
                background-image: url('~@/assets/img/noMessage/noScreen.png');
                background-size: 30% 60%;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }

        .screenChooseName {
            margin-top: 10px;
            width: 100%;
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-size: 20px;
            color: #fff;
            background-image: url('~@/assets/images/screenBackNameNormal.png');
            background-size: 100% 100%;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &:hover {

            // color: @currentColor;
            .screenChooseName {
                background-image: url('~@/assets/images/screenBackNameHover.png');
                background-size: 100% 100%;
                background-position: center center;
                background-repeat: no-repeat;
            }

            .screenChooseItem {
                border-color: #E39F28;
            }
        }
    }
}
::-webkit-scrollbar-thumb{
  background-color: #a8a8a8 !important;
}
</style>
